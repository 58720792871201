import { toString, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { ReportRequest, ApiMessageResponse_$reflection, UserResponse_$reflection, PagedDataResponse$1_$reflection, ShippingMatrixReportResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { union_type, record_type, int32_type, bool_type, list_type, unit_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { render as render_2, update as update_1, extractName, init as init_1, SelectedItemsChangedData_$reflection, Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_1 } from "../Components/MultiSelectSearch.fs.js";
import { Default_pagedData, SearchFilterKey, DataSourceItem_$reflection } from "../Models.fs.js";
import { StateSelectFilterHelper_renderSearch, StateSelectFilter, TypeStateHeld, StateSelectFilterHelper_tryGet, StateSelectFilterHelper_extractMultiSelectFilter, StateSelectFilterModule_addMultiSearchFilter, TypeStateHeldModule_toMultiSearchState, StateSelectFilter_$reflection } from "../Utilities/StateSelectFilterHelper.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { isEmpty, map, ofArray, empty, choose, toArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { createObj, defaultOf, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Int32_fromString } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { getShipToODM } from "../API/DataSourceAPI.fs.js";
import { userHasClaim } from "../Components/ClaimHelpers.fs.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { exportShippingMatrix, getShippingMatrix } from "../API/ShippingMatrixAPI.fs.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { map as map_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";

export class State extends Record {
    constructor(PagedDataDeferred, ExportDeferred, AccountSearch, SelectedAccounts, SelectedWarehouses, SelectFilters, SelectedProjects, CurrentUser, ShowProjectSearch, Page) {
        super();
        this.PagedDataDeferred = PagedDataDeferred;
        this.ExportDeferred = ExportDeferred;
        this.AccountSearch = AccountSearch;
        this.SelectedAccounts = SelectedAccounts;
        this.SelectedWarehouses = SelectedWarehouses;
        this.SelectFilters = SelectFilters;
        this.SelectedProjects = SelectedProjects;
        this.CurrentUser = CurrentUser;
        this.ShowProjectSearch = ShowProjectSearch;
        this.Page = (Page | 0);
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.ShippingMatrixQuery.State", [], State, () => [["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(ShippingMatrixReportResponse_$reflection()))], ["ExportDeferred", Deferred$1_$reflection(unit_type)], ["AccountSearch", State_$reflection_1()], ["SelectedAccounts", list_type(DataSourceItem_$reflection())], ["SelectedWarehouses", list_type(DataSourceItem_$reflection())], ["SelectFilters", list_type(StateSelectFilter_$reflection())], ["SelectedProjects", list_type(DataSourceItem_$reflection())], ["CurrentUser", UserResponse_$reflection()], ["ShowProjectSearch", bool_type], ["Page", int32_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["QueryAsync", "ExportAsync", "MultiSelectSearchMsg", "SetMultiSelectSearchItems", "SetNextPage", "SetPreviousPage"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.ShippingMatrixQuery.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(ShippingMatrixReportResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(ShippingMatrixReportResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", Msg_$reflection_1()]], [["Item", SelectedItemsChangedData_$reflection()]], [], []]);
}

const requiredMultiSearchFilterDataList = singleton(7);

function userHasAllRequiredMultiSearchFilterData(currentUser) {
    return !equals(currentUser.Accounts, void 0);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

function buildReportRequest(state) {
    return new ReportRequest("ShippingMatrix.xlsx", "", toArray(choose((x) => Int32_fromString(x.Id), state.SelectedProjects)), new Int32Array([]), toArray(choose((x_1) => Int32_fromString(x_1.Id), state.SelectedAccounts)), new Int32Array(0), new Int32Array(0), new Int32Array([]), new Int32Array([]), [], new Int32Array([]), [], [], [], [], [], [], [], new Int32Array([]), [], [], "", "None", "None", defaultOf(), "", "", defaultOf(), defaultOf(), state.Page);
}

export function convertStateToMultiSearch(typeHeldState) {
    return TypeStateHeldModule_toMultiSearchState(typeHeldState);
}

export function init(user) {
    let ShowProjectSearch;
    const patternInput = init_1(new SearchFilterKey(12, []), (searchCriteria) => getShipToODM(user, searchCriteria));
    const accountSearchState = patternInput[0];
    const selectFilters = [];
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(12, []), accountSearchState)));
    return [(ShowProjectSearch = userHasClaim(user, 16), new State(new Deferred$1(0, []), new Deferred$1(0, []), accountSearchState, empty(), empty(), ofArray(selectFilters.slice()), empty(), user, ShowProjectSearch, 1)), Cmd_batch(ofArray([Cmd_map((Item) => (new Msg(2, [Item])), patternInput[1]), singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    })]))];
}

export function update(msg, state) {
    let input_3, input_5;
    switch (msg.tag) {
        case 5:
            if (hasPreviousPage(state)) {
                return [new State(state.PagedDataDeferred, state.ExportDeferred, state.AccountSearch, state.SelectedAccounts, state.SelectedWarehouses, state.SelectFilters, state.SelectedProjects, state.CurrentUser, state.ShowProjectSearch, state.Page - 1), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_1;
                    const input_1 = msg.fields[0].fields[0];
                    command_1 = ((input_1.tag === 1) ? Alert_error(Alert_title("Shipping Matrix Query", Alert_texts(ofArray(input_1.fields[0].Messages)))) : Cmd_none());
                    return [new State(new Deferred$1(2, [(input_3 = msg.fields[0].fields[0], (input_3.tag === 1) ? Default_pagedData() : input_3.fields[0])]), state.ExportDeferred, state.AccountSearch, state.SelectedAccounts, state.SelectedWarehouses, state.SelectFilters, state.SelectedProjects, state.CurrentUser, state.ShowProjectSearch, state.Page), command_1];
                }
                default:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(new Deferred$1(1, []), state.ExportDeferred, state.AccountSearch, state.SelectedAccounts, state.SelectedWarehouses, state.SelectFilters, state.SelectedProjects, state.CurrentUser, state.ShowProjectSearch, state.Page), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, getShippingMatrix, buildReportRequest(state), (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 1:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, new Deferred$1(2, [void 0]), state.AccountSearch, state.SelectedAccounts, state.SelectedWarehouses, state.SelectFilters, state.SelectedProjects, state.CurrentUser, state.ShowProjectSearch, state.Page), (input_5 = msg.fields[0].fields[0], (input_5.tag === 1) ? Alert_error(Alert_title("Export Shipping Matrix Query", Alert_texts(ofArray(input_5.fields[0].Messages)))) : Cmd_none())];
                default:
                    if (equals(state.ExportDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, new Deferred$1(1, []), state.AccountSearch, state.SelectedAccounts, state.SelectedWarehouses, state.SelectFilters, state.SelectedProjects, state.CurrentUser, state.ShowProjectSearch, state.Page), Cmd_OfAsyncWith_perform((computation_1) => {
                            startImmediate(computation_1);
                        }, exportShippingMatrix, buildReportRequest(state), (arg_2) => (new Msg(1, [new AsyncMsg$1(2, [arg_2])])))];
                    }
            }
        case 2: {
            const filterId = extractName(msg.fields[0]);
            const multiSearchFilter = StateSelectFilterHelper_extractMultiSelectFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId));
            if (multiSearchFilter != null) {
                const patternInput = update_1(msg.fields[0], multiSearchFilter);
                const componentIntent = patternInput[2];
                const componentCmd = patternInput[1];
                const command_4 = (componentIntent.tag === 1) ? Cmd_map((Item_5) => (new Msg(2, [Item_5])), componentCmd) : Cmd_batch(ofArray([Cmd_map((Item_4) => (new Msg(2, [Item_4])), componentCmd), singleton((dispatch_2) => {
                    dispatch_2(new Msg(3, [componentIntent.fields[0]]));
                })]));
                return [new State(state.PagedDataDeferred, state.ExportDeferred, state.AccountSearch, state.SelectedAccounts, state.SelectedWarehouses, map((x_4) => {
                    if (toString(x_4.Id) === filterId) {
                        return new StateSelectFilter(x_4.Id, new TypeStateHeld(0, [patternInput[0]]), x_4.SelectedItems);
                    }
                    else {
                        return x_4;
                    }
                }, state.SelectFilters), state.SelectedProjects, state.CurrentUser, state.ShowProjectSearch, state.Page), command_4];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 3:
            return [new State(state.PagedDataDeferred, state.ExportDeferred, state.AccountSearch, state.SelectedAccounts, state.SelectedWarehouses, map((x_6) => {
                if (toString(x_6.Id) === msg.fields[0].Name) {
                    return new StateSelectFilter(x_6.Id, x_6.State, msg.fields[0].Items);
                }
                else {
                    return x_6;
                }
            }, state.SelectFilters), state.SelectedProjects, state.CurrentUser, state.ShowProjectSearch, isEmpty(msg.fields[0].Items) ? state.Page : 1), singleton((dispatch_3) => {
                dispatch_3(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
        default:
            if (hasNextPage(state)) {
                return [new State(state.PagedDataDeferred, state.ExportDeferred, state.AccountSearch, state.SelectedAccounts, state.SelectedWarehouses, state.SelectFilters, state.SelectedProjects, state.CurrentUser, state.ShowProjectSearch, state.Page + 1), singleton((dispatch) => {
                    dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
    }
}

export function showLoadingSpinner(state) {
    return !userHasAllRequiredMultiSearchFilterData(state.CurrentUser);
}

export function render(state, dispatch) {
    let elems_21, elems_14, elems_1, elems, matchValue_1, pagedData, elems_13, elems_12, elems_11, elems_2, elems_10, elems_9, elems_4, elems_3, elems_6, elems_5, elems_8, elems_7, elems_20;
    if (showLoadingSpinner(state)) {
        return render_1();
    }
    else {
        return createElement("div", createObj(singleton((elems_21 = [createElement("div", createObj(ofArray([["className", "box"], (elems_14 = [createElement("h1", {
            className: join(" ", ["has-text-link", "has-text-weight-semibold"]),
            children: "Shipping Matrix",
        }), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-items-center"])], (elems = [createElement("h1", {
            className: join(" ", ["has-text-link"]),
            children: (matchValue_1 = state.PagedDataDeferred, (matchValue_1.tag === 1) ? "Searching..." : ((matchValue_1.tag === 2) ? ((pagedData = matchValue_1.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_13 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_2(convertStateToMultiSearch(filter.State), (arg) => {
            dispatch(new Msg(2, [arg]));
        }), new SearchFilterKey(12, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-right"])], (elems_9 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", !hasPreviousPage(state)], ["onClick", (_arg) => {
            dispatch(new Msg(5, []));
        }], (elems_4 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_3 = [createElement("i", {
            children: ["navigate_before"],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("span", {
            className: join(" ", ["is-sr-only"]),
            children: "Previous",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state)], ["onClick", (_arg_1) => {
            dispatch(new Msg(4, []));
        }], (elems_6 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_5 = [createElement("i", {
            children: ["navigate_next"],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("span", {
            className: join(" ", ["is-sr-only"]),
            children: "Next",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", equals(state.ExportDeferred, new Deferred$1(1, []))], ["onClick", (_arg_2) => {
            dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
        }], (elems_8 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "mr-3"])], (elems_7 = [createElement("i", {
            children: ["file_download"],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("span", {
            children: equals(state.ExportDeferred, new Deferred$1(1, [])) ? "Exporting..." : "Export",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_20 = toList(delay(() => {
            let elems_19, elems_18, elems_15, children, elems_17;
            const matchValue_2 = state.PagedDataDeferred;
            switch (matchValue_2.tag) {
                case 1:
                    return singleton_1(render_1());
                case 2: {
                    const pagedData_1 = matchValue_2.fields[0];
                    if (pagedData_1.TotalCount === 0) {
                        return singleton_1(createElement("div", {
                            className: join(" ", ["has-text-centered"]),
                            children: "No items were found that meet the search criteria",
                        }));
                    }
                    else {
                        const tableHeader = (header) => createElement("th", {
                            className: join(" ", ["px-3", "py-2", "has-text-link"]),
                            children: header,
                        });
                        const tableData = (text) => createElement("td", {
                            className: join(" ", ["px-3", "py-2"]),
                            children: text,
                        });
                        return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_19 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_18 = [createElement("thead", createObj(singleton((elems_15 = [(children = ofArray([tableHeader("Warehouse Name"), tableHeader("Ship To ODM"), tableHeader("Transit Time"), tableHeader("Truck Cutoff"), tableHeader("Monday"), tableHeader("Tuesday"), tableHeader("Wednesday"), tableHeader("Thursday"), tableHeader("Friday"), tableHeader("Saturday"), tableHeader("Sunday")]), createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])))), createElement("tbody", createObj(singleton((elems_17 = toList(delay(() => map_1((item) => {
                            let elems_16;
                            return createElement("tr", createObj(singleton((elems_16 = [tableData(item.WarehouseName), tableData(item.ShipToODM), tableData(item.TransitTime), tableData(item.TruckCutoff), tableData(item.Monday), tableData(item.Tuesday), tableData(item.Wednesday), tableData(item.Thursday), tableData(item.Friday), tableData(item.Saturday), tableData(item.Sunday)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))]))));
                        }, pagedData_1.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))));
                    }
                }
                default:
                    return singleton_1(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "Select filters to run the query",
                    }));
            }
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))]))));
    }
}

