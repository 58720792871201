import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { contains, map, sortBy, where } from "../fable_modules/fable-library.4.10.0/Array.js";
import { comparePrimitives, numberHash, defaultOf, int32ToString } from "../fable_modules/fable-library.4.10.0/Util.js";
import { DataSource, SearchFilterKey, DataSourceItem } from "../Models.fs.js";
import { toString } from "../fable_modules/fable-library.4.10.0/Types.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";

export function commonSearchProjects(selectFilters, filterKey, ownerIds, projects, searchCriteria) {
    return singleton.Delay(() => {
        let array_1;
        const ownerIdsLength = ownerIds.length | 0;
        const data = where((x_3) => (x_3.DisplayValue.toLocaleLowerCase().indexOf(searchCriteria.toLocaleLowerCase()) >= 0), sortBy((x_1) => x_1.DisplayValue, map((item_2) => (new DataSourceItem(int32ToString(item_2.Id), (item_2.Name === defaultOf()) ? "" : item_2.Name, item_2)), (array_1 = projects.filter((item) => !((item.Name === "") ? true : (item.Name === defaultOf()))), array_1.filter((item_1) => {
            if (ownerIdsLength === 0) {
                return true;
            }
            else {
                return contains(item_1.OwnerId, ownerIds, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: numberHash,
                });
            }
        }))), {
            Compare: comparePrimitives,
        }));
        return singleton.Return(new FSharpResult$2(0, [new DataSource(toString(new SearchFilterKey(1, [])), false, data)]));
    });
}

